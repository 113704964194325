import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Seo from "../components/Seo";
import Layout from "../components/Layout";
import Hero from "../components/Hero";

const imageData = {
  "womens-haircut.jpg":
    "creating fresh, trendy cuts that are tailored to what you want",
  "womens-haircut-blonde.jpg": "a short, sassy cut for beautiful blonde hair",
  "vivid-color-red-straight-hair.jpg":
    "vivid red hair color that extends to the end of this long hairstyle",
  "vivid-color-red-curly-hair.jpg":
    "vivid red hair color on a medium short cut styled with loose waves",
  "vivid-color-plum.jpg": "vivid plum hair color with added purple highlights",
  "vivid-color-black.jpg":
    "intense black hair color on a trendy medium bob cut",
  "updo-brunette.jpg": "a braided updo into a thick bun with loose strands",
  "touch-up-blonde.jpg": "a color touch-up to strawberry blonde hair",
  "tinsel.jpg": "add shiny tinsel to your hair to create an eye-catching look",
  "style-hair.jpg": "styling hair to give you a special look",
  "quinceanera-hairstyle.jpg": "a quinceanera hairstyle for a fancy event",
  "partial-highlights.jpg":
    "placing highlights in strategic areas adding to your look",
  "partial-highlights-lowlights.jpg":
    "adding highlights close to your natural shade create dimension and depth",
  "partial-highlights-brunette.jpg":
    "added blonde highlights around face to frame",
  "partial-highlights-brunette-style.jpg":
    "light highlights to the back of brunette hair add interest and freshness",
  "mens-haircut.jpg":
    "offering quick, specific cuts for your look whether classic or cutting edge",
  "highlights-repair-treatment.jpg":
    "restored damaged hair from well-water and added highlights to create healthy vibrant hair",
  "highlights-brunette.jpg":
    "added subtle highlights to long brunette hair to create a stunning, timeless look",
  "highlights-blonde.jpg":
    "soft blonde highlights to brunette hair creates a fresh, summer look",
  "highlights-blonde-straight.jpg":
    "blonde highlights to blonde hair creates depth and vibrancy that stands out",
  "highlight-root-touch-up.jpg":
    "touching up your highlights to make them fresh again while keeping your hair healthy",
  "balayage.jpg":
    "soft natural highlights make for a stunning, picture-worthy look",
  "braid-brunette.jpg": "intricate style with multiple braids",
  "bridal-hair-blonde-braid.jpg": "a thick braided half updo with waves",
  "bridal-hair-loose-curls-brunette.jpg":
    "half updo with loose falling curls to create a stunning look from behind",
  "bridal-party-hair.jpg":
    "creating stunning looks for you and your entire bridal party",
  "bridal-hair-updo-closeup.jpg":
    "a close up to show the intricacy of what can be created",
  "bridal-hair-updo.jpg":
    "artistic, intricate, picture-worthy updo for your wedding day making you the center of attention",
  "gloss.jpg": "a hair treatment that adds intense shine",
  "updo.jpg": "a braided updo with loose strands",
  "bridal-hair-curly-blonde-flowers.jpg":
    "half updo with perfect curls and flowers extending down the length of hair",
  "all-over-color-red.jpg": "create a new look with all over color",
  "bridal-hair-blonde.jpg":
    "braided half updo with loose flowing curls and flowers",
  "cornrows.jpg": "tight, neat cornrows makes a trendy statement",
  "all-over-highlights.jpg":
    "create a dramatic or subtle look with all over highlights",
};

const PortfolioPage = ({ data }) => {
  const { portfolioImages } = data;
  const images = portfolioImages.edges.map(({ node }) => {
    return {
      image: getImage(node),
      alt: imageData[node.relativePath.split("/")[1]],
    };
  });

  return (
    <Layout>
      <Hero>
        <h2 className="font-light text-3xl mt-32 sm:mt-0 tracking-widest sm:text-5xl small-caps text-gray-300">
          Portfolio
        </h2>
        <div className="grid max-w-6xl mt-8 grid-cols-1 sm:grid-cols-3 gap-8 px-4">
          {images.map(({ image, alt }, i) => (
            <GatsbyImage key={i} image={image} alt={alt} />
          ))}
        </div>
      </Hero>
    </Layout>
  );
};

export const Head = () => <Seo title="Porfolio" />;

export const pageQuery = graphql`
  query PortfolioQuery {
    portfolioImages: allFile(
      filter: { relativeDirectory: { eq: "portfolio" } }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData(aspectRatio: 1)
          }
        }
      }
    }
  }
`;

export default PortfolioPage;
